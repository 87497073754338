import React from "react"
import { Link, graphql } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"
import HomepageTemplate from '../templates/homepage';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import CookieBanner from '../components/CookieBanner';

const IndexPage = ({data}) => {

  return (
    <>
      <Layout props={ { 
        title: data.markdownRemark.frontmatter.title, 
        meta: data.markdownRemark.frontmatter.meta 
        }}
      >
        <HomepageTemplate 
          html={ data.markdownRemark.html }
          title={ data.markdownRemark.frontmatter.title }
          subtitle={ data.markdownRemark.frontmatter.subtitle }
          ctatitle={ data.markdownRemark.frontmatter.ctatitle }
          appstore={ data.markdownRemark.frontmatter.appstore }
          playstore={ data.markdownRemark.frontmatter.playstore }
          appstoreIcon={ data.markdownRemark.frontmatter.appstoreIcon }
          playstoreIcon={ data.markdownRemark.frontmatter.playstoreIcon }
          headerimage={ data.markdownRemark.frontmatter.headerimage }
          supplementaryimage={ data.markdownRemark.frontmatter.supplementaryimage }
        />
        <Footer />
        <CookieBanner />
      </Layout>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter:{templateKey: {eq: "homepage"}}) {
      html
      frontmatter {
        title
        meta
        subtitle
        ctatitle
        appstore
        appstoreIcon
        playstore
        playstoreIcon
        headerimage
        supplementaryimage
      }
    }
  }
`;
